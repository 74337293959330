.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body{
  background-color: #1768AC;
  display: flex;
  align-items: center;
  justify-content: center;
}
.App-header {
  background-color: #1768AC;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.post{
  background-color:rgba(6, 190, 225,0.5);
  color:rgba(255,255,255,0.5);
  border-style: solid;
  border-color:white;
  border-radius: 25px;
  padding-top:0%;
  padding: 1%;
  margin: 5%;
  width: 400px;
  text-align: center;
  transition: 1s;

}
.post:hover{
  background-color:rgba(6, 190, 225,1);
  color:rgba(255,255,255,1);
}

#listy{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

#post{
  height: 80px;
  width: 180px;
  border-radius: 15px;
  background-color: #093050;
  color: white;
  font-size: 23px;
  margin-top: 20px;
  margin-left: 50px;
}

#body{
  display: flex;
  flex-direction: column;
}

#form{
  display: none;
}
#submit{
  display:none;
  height: 80px;
  width: 180px;
  border-radius: 15px;
  background-color: #093050;
  color: white;
  font-size: 23px;
  margin-top: 20px;
  margin-left: 0px;
}

.option{
  margin: 10px;
  
}
.label{
  color: white;
  font-size: 20px;
  text-align: right;
  margin-right: 20px;
  float: left;
  width: 100px;
  font-weight: bold;
}

#content{
  height: 100px;
  width: 300px;
}

#title, #username{
  width: 300px;
}

.content{
  margin-top: -15px;
}

.post{
  padding: 15px;
}
